import { Box, CardContent, CardHeader } from '@mui/material';
import ImageNotFound from '@/src/assets/common/images/image_not_found.jpg';
import { getMonthDayYearValue } from '@/src/utilities/helpers/dateParser';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';
import { Community, postState } from '../types';
import { getTenant } from '@/src/utilities/helpers/utils';
import { CommunityCardFooterText, CommunityCardWrapper } from '../styles/CreateCommunity.styled';
import { CardAvatar, CardAvatarImage } from './CommunityCard.styles';

interface CommunityCardProps {
  item: Community;
  setIdSelected: (id: number) => void;
}

const textReload = (text: string) => {
  const titleReload = text?.length > 45 ? `${text?.slice(0, 45)}...` : text;
  return titleReload;
};

const CommunityCard = ({ item, setIdSelected }: CommunityCardProps) => {
  const {
    id,
    title,
    creation_date: creationDate,
    cover_image_url: coverImageUrl,
    subtitle,
    creation_user: creationUser,
    state
  } = item;
  const handleClick = (event: any, selectedId: any) => {
    setIdSelected(selectedId);
  };

  return (
    <CommunityCardWrapper onClick={(event) => handleClick(event, id)} key={id}>
      <CardHeader
        avatar={
          <CardAvatar aria-label="card-label">
            {creationUser?.avatar_url ? (
              <CardAvatarImage src={creationUser?.avatar_url} alt="avatar_image" />
            ) : (
              getTenant()
            )}
          </CardAvatar>
        }
        title={
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { lg: '1fr auto', md: '1fr' },
              width: '100%',
            }}
          >
            <span>{`${creationUser?.name} ${creationUser?.surname}`}</span>
            <span style={{ fontWeight: 'normal' }}>
              {getMonthDayYearValue(creationDate)}
            </span>
            <span
              style={{
                fontWeight: 'bold',
                color: postState[state.code],
                
              }}
            >
              {state.name}
            </span>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: 'bold',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        subheader={`${title ? `${title}` : ''}`}
        subheaderTypographyProps={{
          fontWeight: 'bold',
          height: '20px',
        }}
      />
      <CustomCardMedia key={id} src={coverImageUrl ?? ImageNotFound} alt={title} height="180px" />
      <CardContent>
        <CommunityCardFooterText variant="body2">{textReload(subtitle)}</CommunityCardFooterText>
      </CardContent>
    </CommunityCardWrapper>
  );
};

export default CommunityCard;
