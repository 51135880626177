import { AlertColor } from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import { TLanguagesOptions, TProp } from '@/src/utilities/helpers/commonTypes';

export interface CommunityFormProps extends TProp {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  setFieldError: (field: string, value: string | undefined) => void;
  errors: FormikErrors<FormikValues>;
  isLoadingSubmit: boolean;
  errorMessage: string | null;
  t: (par1: string, par2?: TLanguagesOptions) => string;
  handleChange: (event: any, newValue: number) => void;
  tabValue: number;
  a11yProps: (id: number) => any;
  disableButton: boolean;
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
}

export type TPostsStateOptions = {
  [key: string]: string;
};
export const postState: TPostsStateOptions = {
  Approved: '#516F3F',
  Rejected: '#FF1744',
  Restricted: '#CA8A04',
  InReview: '#ebb134',
};

export interface ArticleBody {
  title: string;
  subtitle: string;
  start_date: number;
  document_url: string;
  video_url: string;
  cover_image_url: string;
  body: string;
  publication_image_urls: string[];
  targets: { roles: []; users: [] };
}

export interface PostByIdBody {
  post: {
    creation_date: string;
    creation_user: {
      avatar_url?: string | undefined;
      id: string;
      name: string;
      surname: string;
    };
    description: string;
    files: Array<{ id: number; url: string; type: string }>;
    id: number;
    state: {
      code: string;
      name: string;
    }
    state_reason: string;
    state_date: string;
    title: string;
  };
}

export interface CreateCommunityModalProps {
  dataById?: PostByIdBody;
  openModal: boolean;
  refetchDataById?: () => void;
  setOpenModal: (x: boolean) => void;
  setSnackBarMessage: (sms: string, sever?: AlertColor) => void;
}
export type Community = {
  cover_image_url: string;
  creation_date: number;
  creation_user: {
    avatar_url: string;
    id: string;
    name: string;
    surname: string;
  };
  id: number;
  subtitle: string;
  title: string;
  state: {
    code: string;
    name: string;
  };
};

export type Users = {
  description: string;
  id: number;
};

export type UsersList = {
  id: number;
  name: string;
  surname: string;
  avatar_url: string;
  language_id: number;
  creation_date: number;
  is_disabled: true;
  disabled_date: number;
  roles: [string];
};
