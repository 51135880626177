import { useQuery } from '@tanstack/react-query';
import { getStyles } from '@/src/api/endpoints/master';

export const useGetStyles = (clientTenantCode: string) => {
  const {
    data: styles,
    isLoading,
    refetch,
  } = useQuery(['styles'], () => getStyles(clientTenantCode), {
    select: (data) => {
      const { style_setting: stylesSetting } = data.data;
      const primaryColor = stylesSetting.primary_color === '' ? null : stylesSetting.primary_color;
      const secondaryColor = stylesSetting.secondary_color === '' ? null : stylesSetting.secondary_color;
      return { ...stylesSetting, primary_color: primaryColor, secondary_color: secondaryColor };
    },
  });

  return { styles, isLoading, refetch };
};
