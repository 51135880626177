import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LoadPackageModalProps } from '../../../types';
import { ApartmentContainer, StyledButton } from '../LoadPackageForm.styled';
import { PropertyType } from '@/src/pages/residents/residents-section/types';

const LoadPackageFormStep2 = ({ setCurrentStep, setFieldValue, propertiesList }: LoadPackageModalProps | any) => {
  const { t } = useTranslation();
  const [showNotEspecifiedFloors, setShowNotEspecifiedFloors] = useState(false);
  const especifiedfloors = propertiesList
    ?.map((p: PropertyType) => p.floor)
    ?.filter((value: number | null) => value !== null)
    ?.filter((value: number, index: number, self: number[]) => self.indexOf(value) === index);

  const notEspecifiedFloors = propertiesList.filter((p: PropertyType) => !p?.floor).map((p: PropertyType) => p?.code);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" sx={{ padding: '10px 0' }}>
            {t('load_package_step_2_subtitle', { ns: 'packages' })}
          </Typography>
        </Grid>
      </Grid>
      <ApartmentContainer container>
        {!showNotEspecifiedFloors
          ? especifiedfloors.map((floor: number) => (
              <Grid item key={`floor_${floor}`} xs={4} sm={3}>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  type="button"
                  onClick={() => {
                    setFieldValue('property_code', floor);
                    setCurrentStep(3);
                  }}
                >
                  {floor}
                </StyledButton>
              </Grid>
            ))
          : notEspecifiedFloors.map((code: string) => (
              <Grid item key={`code_${code}`} xs={4} sm={3}>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  type="button"
                  onClick={() => {
                    setFieldValue('property_code', `${code}`);
                    setCurrentStep(4);
                  }}
                >
                  {code}
                </StyledButton>
              </Grid>
            ))}
      </ApartmentContainer>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!!notEspecifiedFloors.length && (
          <Button
            sx={{ ml: { sm: '10px', xs: '0px' }, mt: 2, width: { xs: '100%', sm: 'auto' } }}
            type="button"
            onClick={() => {
              setShowNotEspecifiedFloors(!showNotEspecifiedFloors);
            }}
            variant="outlined"
            size="small"
          >
            {!showNotEspecifiedFloors
              ? t('packages_not_especified_button', { ns: 'packages' })
              : t('packages_especified_button', { ns: 'packages' })}
          </Button>
        )}
        <Button
          sx={{ ml: { sm: '10px', xs: '0px' }, mt: 2, width: { xs: '100%', sm: 'auto' } }}
          type="button"
          onClick={() => setCurrentStep(1)}
          variant="outlined"
          size="small"
        >
          {t('back')}
        </Button>
      </Box>
    </Box>
  );
};

export default LoadPackageFormStep2;
