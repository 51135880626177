import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import CustomInput from '@/src/components/forms/CustomInput';
import { NUMBER_REGEX } from '@/src/utilities/helpers/constants';
import { DeliveryPackageFormProps } from '../../types';
import { ApartmentContainer, StyledButton } from '../load-package-form/LoadPackageForm.styled';
import { PropertyType } from '@/src/pages/residents/residents-section/types';
import { getProperties } from '@/src/api/endpoints/residents';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

const DeliverPackage = ({
  handleSubmit,
  values,
  setFieldValue,
  errors,
  sendPackageDelivery,
  isLoadingSubmit,
  handleToggle,
  handleToggleAll,
  detail,
  isLoading,
  number,
  searchDone,
}: DeliveryPackageFormProps) => {
  const { t } = useTranslation();

  const { data: response } = useQuery(
    ['getProperties'],
    () => getProperties({}),
    
  );
  const propertiesList = response?.data.properties;

  const [ showCustomerCode , setShowCustomerCode ] = useState(false);

  const especifiedfloors = propertiesList
    ?.map((p: PropertyType) => p.floor)
    ?.filter((value: number | null) => value !== null)
    ?.filter((value: number, index: number, self: number[]) => self.indexOf(value) === index);

    const apartments = propertiesList
    .filter((p: PropertyType) => {
      const splitFloor = p.code.split('-');
      return Number(splitFloor[0]) === Number(values.propertyCode);
    })
    ?.map((v: any) => v.code.split('-').pop());


    const setPropertyCode = (apartment: string) => {
      const baseList = propertiesList.filter((p: PropertyType) => {
        const splitFloor = p.code.split('-');
        return Number(splitFloor[0]) === Number(values.propertyCode);
      });
      console.log(baseList.filter((l: PropertyType) => l.code.includes(apartment)));
      return baseList.filter((l: PropertyType) => l.code.includes(apartment))[0].code as string;
    };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{t('modal_deliver_package_sub_title', { ns: 'packages' })}</Typography>
        </Grid>
        
        {
          !values?.propertyCode && !showCustomerCode ? (
            <ApartmentContainer container sx={{ mb: '20px' }}>
              {especifiedfloors.map((floor: number) => (
                <Grid item key={`floor_${floor}`} xs={12} sm={6}>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    type="button"
                    onClick={() => {
                      setFieldValue('propertyCode', `${floor}`);
                    }}
                  >
                    {floor}
                  </StyledButton>
                </Grid>
              ))}
            </ApartmentContainer>
          ) : null
        }

        {
          values?.propertyCode && !showCustomerCode ? (
            <ApartmentContainer container sx={{ mb: '20px' }}>
              {apartments.map((apartment: string) => (
                <Grid item key={`apartment_${apartment}}`} xs={4} sm={3}>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    type="button"
                    onClick={() => {
                      setFieldValue('propertyCode', setPropertyCode(apartment));
                      setShowCustomerCode(true);
                    }}
                  >
                    {apartment}
                  </StyledButton>
                </Grid>
              ))}
            </ApartmentContainer>
          ) : null
        }

        {
          showCustomerCode && (
            <>
              <Grid item xs={12} sm={!searchDone ? 5 : 6} sx={{mr: '10px', mt: '10px'}}>
                <CustomInput
                  field="propertyCode"
                  setFieldValue={setFieldValue}
                  label={t('modal_deliver_package_input_propertyCode_label', { ns: 'packages' })}
                  placeholder={t('modal_deliver_package_input_propertyCode_placeholder', { ns: 'packages' })}
                  values={values}
                  disabled={showCustomerCode}
                  errors={errors}
                  required
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={!searchDone ? 5 : 6} sx={{ mt: '10px'}}>
                <CustomInput
                  inputType="number"
                  field="customerCode"
                  setFieldValue={setFieldValue}
                  label={t('modal_deliver_package_input_customerCode_label', { ns: 'packages' })}
                  placeholder={t('modal_deliver_package_input_customerCode_placeholder', { ns: 'packages' })}
                  values={values}
                  disabled={searchDone}
                  regex={NUMBER_REGEX}
                  errors={errors}
                  required
                />
              </Grid>
            </>
          )
        }
               
      {
        !searchDone && (
          <>
            {
            values?.propertyCode && (
              <>
                <Grid container display={'flex'} justifyContent={'end'} sx={{ marginTop: '10px'}}>
                  <Grid item xs={12} sm={2} sx={{ marginRight: '8px' }}>
                    <Button
                      type="button"
                      variant="outlined"
                      sx={{ width: '100%' }}
                      onClick={() => {
                        setShowCustomerCode(false);
                       setFieldValue('propertyCode', '')} }
                    >
                      {t('packages_page_back_button', { ns: 'packages' })}
                    </Button>
                  </Grid>
                  {
                    showCustomerCode && (
                      <Grid item xs={12} sm={2}>
                        <Button
                          type="submit"
                          variant="outlined"
                          sx={{ width: '100%' }}
                        >
                          {t('packages_page_search_button', { ns: 'packages' })}
                        </Button>
                      </Grid>
                    )
                  }
                  
                </Grid>
              </>
            )
          }
          </>
        )
      }
        {isLoading && (
          <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Grid>
        )}
        {detail.error && (
          <Grid item xs={12} container justifyContent="center">
            <FormHelperText filled sx={{ fontSize: '16px' }}>
              {detail.error}
            </FormHelperText>
          </Grid>
        )}
        {detail.packages?.length > 0 && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">
                {t('modal_deliver_package_text_packages_available', { ns: 'packages', unit: values.propertyCode })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">
                {t('modal_deliver_package_text_person_who_withdraws', { ns: 'packages' })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <List sx={{ maxHeight: '350px', overflow: 'auto' }}>
                <ListItem key={2} dense button onClick={handleToggleAll}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={!detail.packages.find((el: any) => el.status !== true)}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={t('modal_deliver_package_text_checkbox_all', { ns: 'packages' })} />
                </ListItem>
                {detail.packages.map((value: any) => (
                  <ListItem key={value.internal_code} dense button onClick={() => handleToggle(value.internal_code)}>
                    <ListItemIcon>
                      <Checkbox edge="start" checked={value.status} disableRipple />
                    </ListItemIcon>
                    <Grid item xs={12} sm={3}>
                      <ListItemText
                        primary={value.carrier}
                        secondary={`${t('code', { ns: 'packages' })}: ${value.internal_code}`}
                      />
                      <ListItemText
                        sx={{ marginTop: '6px' }}
                        secondary={`${t('rack', { ns: 'packages' })} ${value.location ? value.location : '-'}`}
                      />
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} sm={6} container direction="column" alignItems="center" sx={{ mt: 4 }}>
              <Avatar alt={detail.name} src={detail.avatarUrl} sx={{ height: '110px', width: '110px' }} />
              <Typography variant="subtitle1" mt={1}>{`${detail.name} ${detail.surname}`}</Typography>
            </Grid>
          </>
        )}
        <Grid container item xs={12} justifyContent="flex-end">
          {searchDone && (
            <LoadingButton
              loading={isLoadingSubmit}
              type="button"
              variant="contained"
              size="small"
              onClick={() => sendPackageDelivery(detail)}
              sx={{ marginLeft: '1%', width: { xs: '100%', sm: 'auto' } }}
            >
              {t('modal_load_package_deliver_button_text', {
                item: number > 0 ? number : ' ',
                text: t(number > 1 ? 'packages_page_title' : 'info_package_title', { ns: 'packages' }),
                ns: 'packages',
              })}
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeliverPackage;
