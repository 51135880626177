import { FormikValues, useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useOnFindResidentInfo, useSubmitResident } from '../hooks/usePeopleEndpoint';
import { VALID_NUMBER_FIELD } from '@/src/utilities/helpers/constants';
import { CustomDataTablePropsPeople } from '../types';
import { RegisterUser } from './RegisterUserPage';
import { getProperties } from '@/src/api/endpoints/residents';

const RegisterUserPageContainer = ({ close, setSnackBarMessage }: CustomDataTablePropsPeople) => {
  const [open, setOpen] = useState(true);
  const [savedNumbers, setSavedNumbers] = useState({ countryIsoCode: '', countryCode: '+549', number: '' });
  const [dropInputs, setDropInputs] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [searchResponseError, setSearchResponseError] = useState('');
  const { mutate, residentInfo, isLoadingSearchPhone } = useOnFindResidentInfo();
  const { createResident, updateResidentFound, isLoadingSubmitResident } = useSubmitResident();
  const { t } = useTranslation();
  const { data: propertieData } = useQuery(
    ['getProperties'],
    () => getProperties({}),
  );
  
  const isCreateModal = true;
  const firstRoomCode: string | undefined = propertieData?.data.properties[0]?.code;

  // FORMIK DECLATARION
  const getInitialValues = () => ({
    countryCode: savedNumbers.countryCode,
    countryIsoCode: savedNumbers.countryIsoCode,
    datePickerDisabled: false,
    email: residentInfo?.email ?? '',
    from: null,
    functional_unit: residentInfo?.property_code ?? firstRoomCode,
    isReadOnly: !!residentInfo,
    name: residentInfo?.name ?? '',
    phoneNumber: savedNumbers.number,
    showOtherInputs: dropInputs,
    surname: residentInfo?.surname ?? '',
    to: null,
    unitDisabled: false,
    has_deposit: residentInfo?.has_deposit ?? false,
    deposit_amount: residentInfo?.deposit_amount ?? '',
    leaseSigner: residentInfo?.is_lease_signer ?? true,
    residencyType: null,
  });

  const getValidationSchema = () =>
    residentInfo
      ? Yup.lazy(() =>
          Yup.object().shape({
            from: Yup.string()
              .required(t('required_field', { ns: 'errors' }))
              .typeError(t('required_field', { ns: 'errors' })),
            functional_unit: Yup.string().required(t('required_field', { ns: 'errors' })),
            to: Yup.string()
              .required(t('required_field', { ns: 'errors' }))
              .typeError(t('required_field', { ns: 'errors' })),
            residencyType: Yup.object()
              .shape({
                id: Yup.number(),
                code: Yup.string(),
                name: Yup.string(),
              })
              .nullable()
              .required(t('required_field', { ns: 'errors' })),
          }),
        )
      : Yup.lazy(() =>
          Yup.object().shape({
            countryCode: Yup.string().required(t('required_field', { ns: 'errors' })),
            countryIsoCode: Yup.string().required(t('required_field', { ns: 'errors' })),
            email: Yup.string()
              .email(t('yup_required_email'))
              .required(t('required_field', { ns: 'errors' }))
              .trim(),
            from: Yup.string()
              .required(t('required_field', { ns: 'errors' }))
              .typeError(t('required_field', { ns: 'errors' })),
            functional_unit: Yup.string().required(t('required_field', { ns: 'errors' })),
            name: Yup.string()
              .required(t('required_field', { ns: 'errors' }))
              .min(3, `${t('name')} ${t('field_value_min_val', { ns: 'residents' })}`),
            phoneNumber: Yup.string().required(t('required_field', { ns: 'errors' })),
            surname: Yup.string()
              .required(t('required_field', { ns: 'errors' }))
              .min(3, `${t('surname')} ${t('field_value_min_val', { ns: 'residents' })}`),
            to: Yup.string()
              .required(t('required_field', { ns: 'errors' }))
              .typeError(t('required_field', { ns: 'errors' })),
            residencyType: Yup.object()
              .shape({
                id: Yup.number(),
                code: Yup.string(),
                name: Yup.string(),
              })
              .nullable()
              .required(t('required_field', { ns: 'errors' })),
            has_deposit: Yup.boolean(),
            deposit_amount: Yup.number().when('has_deposit', {
              is: true,
              then: Yup.number()
                .required(t('required_field', { ns: 'errors' }))
                .moreThan(0, t('must_be_greater_than_zero', { ns: 'errors' }))
                .integer(t('must_be_integer', { ns: 'errors' })),
            }),
          }),
        );

  const events = {
    onSuccess: () => {
      close();
      setSnackBarMessage(t('success'));
    },
    onError: ({ response }: any) => {
      setResponseError(
        t(response.data.error_messages[0].code, {
          resource: response.data.error_messages[0].message.replace('Property Code', 'Apartment'),
          item: t('functional_unit_error', { ns: 'residents' }),
          ns: 'errors',
        }),
      );
    },
  };

  const onSubmit = (values: FormikValues) => {
    setResponseError('');
    if (!residentInfo) {
      const value = {
        country_iso_code: values.countryIsoCode,
        country_code: values.countryCode,
        email: values.email.trim(),
        from: values.from,
        name: values.name,
        phone_number: values.phoneNumber,
        property_code: values.functional_unit.toUpperCase(),
        role: values.role,
        surname: values.surname,
        to: values.to,
        has_deposit: values.has_deposit,
        deposit_amount: values.has_deposit ? parseFloat(values.deposit_amount) : null,
        residency_type_id: values.residencyType.id,
      };
      return createResident(value, { ...events });
    }

    const valueUpdate = {
      user_id: residentInfo?.user_id,
      person_id: residentInfo.id,
      residency_type_id: values.residencyType.id,
      property_code: values.functional_unit,
      from: values.from,
      to: values.to,
      has_deposit: values.has_deposit,
      deposit_amount:
        values.has_deposit && values.deposit_amount !== '' ? parseFloat(values.deposit_amount) || 0 : null,
    };
    return updateResidentFound(valueUpdate, { ...events });
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  // PHONE SEARCH FORMIK SCHEMA

  const getInitialValuesSearch = () => ({
    countryCode: '',
    phoneNumber: '',
    countryIsoCode: '',
  });

  const getValidationSearch = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        countryCode: Yup.string().required(`${t('required_field', { ns: 'errors' })}`),
        phoneNumber: Yup.string()
          .required(`${t('required_field', { ns: 'errors' })}`)
          .matches(VALID_NUMBER_FIELD, t('yup_required_phone'))
          .min(7, `${t('phone_number', { ns: 'residents' })} ${t('field_value_min_val', { ns: 'residents' })}`)
          .max(15, `${t('phone_number', { ns: 'residents' })} ${t('field_value_max_val', { ns: 'residents' })}`),
      }),
    );

  const searchEvents = {
    onSuccess: () => {
      setDropInputs(true);
    },
    onError: (error: any) => {
      const { data } = error.response;

      if (data?.error_messages?.some((message: { code: string }) => message.code === 'PersonHasDisabledUser')) {
        setSearchResponseError(
          t(data.error_messages[0].code, {
            ns: 'errors',
          }),
        );
      } else {
        setDropInputs(true);
      }
    },
  };

  const onSearchSubmit = (Values: { countryCode: string; countryIsoCode: string; phoneNumber: string }) => {
    const value = {
      countryCode: Values.countryCode,
      number: Values.phoneNumber,
      countryIsoCode: Values.countryIsoCode,
    };
    setSavedNumbers(value);
    mutate(value, searchEvents);
  };

  const {
    handleSubmit: handleSubmitSearch,
    values: valuesSearch,
    setFieldValue: setSearchValue,
    errors: errorsSearch,
  } = useFormik({
    initialValues: getInitialValuesSearch(),
    onSubmit: onSearchSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSearch(),
  });

  const handleClose = () => {
    setOpen(false);
    setDropInputs(false);
    close();
  };

  const childProps = {
    errors,
    errorsSearch,
    handleSubmit,
    handleSubmitSearch,
    isLoading: isLoadingSearchPhone || isLoadingSubmitResident,
    open,
    handleClose,
    setSearchValue,
    setFieldValue,
    values,
    valuesSearch,
    responseError,
    searchResponseError,
    isCreateModal,
  };

  return <RegisterUser {...childProps} />;
};

export default RegisterUserPageContainer;
