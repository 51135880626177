import { Box, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Buildingvalues } from '@/src/pages/building-swap/types';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';
import getEnvVariables from '@/src/utilities/helpers/getEnvVariables';
import { AuthContext } from '@/src/context/auth.context';
import { useGetMenus } from '@/src/hooks/auth/useGetMenus';
import { MenuItem } from '@/src/utilities/helpers/commonTypes';
import { UserContext } from '@/src/context/user.context';
import { useGetStyles } from './hook/useGetStyles';
import BuildingSelector from '@/src/components/building-selector/index';
import { isValidHexaColor } from '@/src/utilities/helpers/utils';

export const BuildingSwap = () => {
  // Misc
  const { t } = useTranslation('auth');
  const { SnackBar, setSnackBarMessage } = useSnackBar();

  // Vite
  const { VITE_TENANT_ID } = getEnvVariables();
  const [currentTenantId, setCurrentTenantId] = useState<string | null>(VITE_TENANT_ID);

  // building used and the array of buildings
  const { actualBuilding: actualBuildingData } = useContext(AuthContext);
  const actualBuilding = actualBuildingData || null;

  // Menus
  const { refetch: refetchMenus } = useGetMenus(currentTenantId as string);
  const { setMenus } = useContext(UserContext);

  // Helpers
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  // Values

  const [values, setValues] = useState<Buildingvalues>({
    firstTowerInstance: actualBuilding?.firstTowerCodeTenant || null,
    secondTowerInstance: actualBuilding?.secondTowerCodeTenant || null,
    thirdTowerInstance: actualBuilding?.thirdTowerCodeTenant || null,
    codeTenant: actualBuilding?.codeTenant || null,
  });

  // styles
  const { refetch: refetchStyles } = useGetStyles(values?.codeTenant as string);
  const { setClientStyle } = useContext(AuthContext);

  const onSubmit = async (data: Buildingvalues) => {
    setIsLoadingButton(true);
    const actualBuildindData = {
      firstTowerCodeTenant: data?.firstTowerInstance,
      secondTowerCodeTenant: data?.secondTowerInstance,
      thirdTowerCodeTenant: data?.thirdTowerInstance,
      codeTenant: data?.codeTenant,
    };
    localStorage.setItem('actualBuildingData', JSON.stringify(actualBuildindData));
    localStorage.setItem('vite_tenant_id', data.codeTenant);
    setCurrentTenantId(data.codeTenant);

    // estilos
    const stylesResponse = await refetchStyles();
    const updatedStyle = stylesResponse.data || null;

    // menus
    const menusResponse = await refetchMenus();
    const updatedMenuList = menusResponse.data || [];

    if (updatedStyle) {
      localStorage.setItem(
        'tower_styles',
        JSON.stringify({
          id: updatedStyle.id,
          main_logo_url: updatedStyle.main_logo_url === '' ? null : updatedStyle.main_logo_url,
          white_logo_url: updatedStyle.white_logo_url === '' ? null : updatedStyle.white_logo_url,
          primary_color: isValidHexaColor(updatedStyle.primary_color) ? updatedStyle.primary_color : null,
          secondary_color: isValidHexaColor(updatedStyle.secondary_color) ? updatedStyle.secondary_color : null,
        }),
      );
      setClientStyle(
        JSON.stringify({
          id: updatedStyle.id,
          main_logo_url: updatedStyle.main_logo_url === '' ? null : updatedStyle.main_logo_url,
          white_logo_url: updatedStyle.white_logo_url === '' ? null : updatedStyle.white_logo_url,
          primary_color: isValidHexaColor(updatedStyle.primary_color) ? updatedStyle.primary_color : null,
          secondary_color: isValidHexaColor(updatedStyle.secondary_color) ? updatedStyle.secondary_color : null,
        }),
      );
    } else {
      localStorage.removeItem('tower_styles');
    }
    if (updatedMenuList.length > 0) {
      localStorage.setItem('menus', JSON.stringify(updatedMenuList));
      setMenus(updatedMenuList as MenuItem[]);
    }
    setIsLoadingButton(false);
    setSnackBarMessage(t('building_swap_succesfull'));
  };

  useEffect(() => {
    const hasChildren = (towerInstance: any) => towerInstance?.has_children;
    const isValid = () => {
      if (!values?.firstTowerInstance) {
        return false;
      }
      if (hasChildren(values?.firstTowerInstance) && !values?.secondTowerInstance) {
        return false;
      }
      if (hasChildren(values?.secondTowerInstance) && !values?.thirdTowerInstance) {
        return false;
      }
      return true;
    };

    setIsButtonDisabled(!isValid());
  }, [values, setIsButtonDisabled]);

  return (
    <Box>
      <Card sx={{ my: 0, mx: { xs: '2%', lg: '10%' }, borderRadius: '.428rem' }}>
        <CardHeader
          title={t('swap_building_screen_title')}
          titleTypographyProps={{ variant: 'h5' }}
          sx={{ borderBottom: `1px solid #ebe9f1`, width: '100%' }}
        />
        <SnackBar />
        <CardContent sx={{ paddingBottom: '0px' }}>
          <BuildingSelector t={t} values={values} setValues={setValues} />
        </CardContent>
        <CardActions>
          <Grid container direction="row" justifyContent="flex-end" gap={1}>
            <LoadingButton
              variant="contained"
              size="medium"
              type="submit"
              sx={{ width: { xs: '100%', sm: 'auto' } }}
              loading={isLoadingButton}
              disabled={isButtonDisabled}
              onClick={() => onSubmit(values)}
            >
              {t('change')}
            </LoadingButton>
          </Grid>
        </CardActions>
      </Card>
      <Grid container item xs={12} justifyContent="flex-end" />
    </Box>
  );
};

export default BuildingSwap;
