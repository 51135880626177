import { useTranslation } from 'react-i18next';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';

import {
  getFormattedDateByLanguague,
  getFormattedLocalFullDateNumberEn,
  getFormattedLocalFullDateNumberEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useVehicleBrands from '@/src/hooks/filters/vehicleBrandsModels.hooks';
import { CarRequestPage } from './CarRequestPage';
import { CarRequestType } from '../types';
import TextLabel from '@/src/components/text-label/TextLabel';
import useCarRequestsStates from '@/src/hooks/filters/carRequestStates.hooks';

export const CarRequestPageContainer = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { apartments } = useApartmentsFilters();
  const { vehicleBrands, vehicleModels } = useVehicleBrands();
  const { carRequestsStates } = useCarRequestsStates();

  const columns: CustomDataTableColumnsProps<CarRequestType>[] = [
    {
      id: 'id',
      name: t('elevator_request_page_table_column_id', { ns: 'carRequest' }),
      selector: (row: CarRequestType) => row?.id,
      sortField: 'id',
      right: true,
      maxWidth: '1%',
    },
    {
      id: 'creation_date',
      name: t('creation_date', { ns: 'carRequest' }),
      selector: (row: CarRequestType) =>
        row.creation_date
          ? getFormattedDateByLanguague(
              lang,
              getFormattedLocalFullDateNumberEs,
              getFormattedLocalFullDateNumberEn,
              row.creation_date,
            )
          : '-',
      sortField: 'creation_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'creation-date',
          query: 'CreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'apartment',
      sortField: 'residency_property_code',
      name: t('user_detail_apartment', { ns: 'carRequest' }),
      selector: (row: CarRequestType) => row?.residency?.property?.code,
      sortable: true,
      center: true,
      filters: apartments,
    },
    {
      id: 'resident',
      sortField: 'holder_full_name',
      name: t('resident', { ns: 'carRequest' }),
      selector: (row: CarRequestType) => row?.customer.full_name,
      sortable: true,
      center: true,
      filters: [
        {
          id: 'customer-full-name',
          query: 'HolderFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'vehicle_brand',
      sortField: 'vehicle_brand',
      name: t('vehicle_brand', { ns: 'carRequest' }),
      selector: (row: CarRequestType) => row?.vehicle?.model?.brand?.name,
      sortable: true,
      center: true,
      filters: vehicleBrands,
    },
    {
      id: 'vehicle_model',
      sortField: 'vehicle_model',
      name: t('vehicle_model', { ns: 'carRequest' }),
      selector: (row: CarRequestType) => row?.vehicle?.model?.name ?? '-',
      sortable: true,
      center: true,
      filters: vehicleModels,
      width: lang === 'en' ? '8%' : '10%',
    },
    {
      id: 'vehicle_plate',
      sortField: 'vehicle_license_plate',
      name: t('vehicle_plate', { ns: 'carRequest' }),
      selector: (row: CarRequestType) => row?.vehicle?.license_plate,
      sortable: true,
      center: true,
      filters: [
        {
          id: 'vehicle-full-plate',
          query: 'VehicleLicensePlate',
          type: 'contains',
        },
      ],
    },
    {
          id: 'vehicle_state',
          name: t('vehicle_state', { ns: 'carRequest' }),
          selector: (row: any) => row?.state?.name,
          sortField: 'car_requests_state_name',
          cell: (row: CarRequestType) => (
            <TextLabel
              text={row.state.name.toLocaleUpperCase()}
              color="#F7F7F7"
              padding="2px 12px"
              width="100px"
              bg={row.state.code === 'COMPLETED' ? '#424867' : '#516F3F'}
            />
          ),
          sortable: true,
          center: true,
          filters: carRequestsStates,
        },
  ];

  const childProps = {
    columns,
    t,
  };

  return <CarRequestPage {...childProps} />;
};

export default CarRequestPageContainer;