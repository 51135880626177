import { Box, Card, CardActions, CardContent, CardHeader, Dialog, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { MenuItem } from '@/src/utilities/helpers/commonTypes';
import getEnvVariables from '@/src/utilities/helpers/getEnvVariables';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { useGetMenus } from '@/src/hooks/auth/useGetMenus';
import { FirebaseContext } from '@/src/context/firebase.context';
import { AuthContext } from '@/src/context/auth.context';
import { UserContext } from '@/src/context/user.context';
import { setUserStorageData } from '@/src/utilities/storage';
import { handleLanguageOnLogin } from '@/src/utilities/helpers/authHelpers';
import { useGetStyles } from '@/src/hooks/common/useGetStyles';
import { useGetRoles } from '@/src/hooks/auth/useGetRoles';
import { Buildingvalues, Tower } from '../building-swap/types';
import BuildingSelector from '@/src/components/building-selector';
import { isValidHexaColor } from '@/src/utilities/helpers/utils';

const SelectTowerModal = (props: Props) => {
  // props
  const { openModal, response, prevUrl } = props;
  const { t } = useTranslation('auth');
  // env
  const { VITE_TENANT_ID } = getEnvVariables();
  // towers group
  const [secondTowersGroup, setSecondTowersGroup] = useState<Tower[]>([]);
  const [thirdTowersGroup, setThirdTowersGroup] = useState<Tower[]>([]);
  // button
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [currentTenantId, setCurrentTenantId] = useState<string | null>(VITE_TENANT_ID);
  // hooks
  const { refetch: refetchStyles } = useGetStyles(currentTenantId || VITE_TENANT_ID);
  const { refetch: refetchMenus } = useGetMenus(currentTenantId || VITE_TENANT_ID);
  const { rolesList, refetch: refetchRoles } = useGetRoles(currentTenantId || VITE_TENANT_ID);
  // context
  const { setMenus } = useContext(UserContext);
  const { setHasNewConnection } = useContext(FirebaseContext);
  const {
    setNoUserAllowedErrorMessage,
    setLoginStatus,
    setPrevUrl,
    setClientStyle,
    setActualBuilding,
    setBuildingsUsed,
    buildingsUsed,
  } = useContext(AuthContext);
  // values
  const [values, setValues] = useState<Buildingvalues>({
    firstTowerInstance: null,
    secondTowerInstance: null,
    thirdTowerInstance: null,
    codeTenant: '',
  });
  // loader

  const onSubmit = async (data: Buildingvalues) => {
    const actualBuildingData = {
      firstTowerCodeTenant: data?.firstTowerInstance ?? {},
      secondTowerCodeTenant: data?.secondTowerInstance ?? {},
      thirdTowerCodeTenant: data?.thirdTowerInstance ?? {},
      codeTenant: data?.codeTenant ?? '',
    };
    localStorage.setItem('actualBuildingData', JSON.stringify(actualBuildingData));
    setActualBuilding(actualBuildingData);
    const buildinsUsedData = {
      firstBuildingsGroup: buildingsUsed.firstBuildingsGroup,
      secondBuildingsGroup: secondTowersGroup,
      thirdBuildingsGroup: thirdTowersGroup,
    };
    localStorage.setItem('buildingsUsed', JSON.stringify(buildinsUsedData));
    setBuildingsUsed(buildinsUsedData);
    setIsButtonLoading(true);
    localStorage.setItem('vite_tenant_id', data?.codeTenant || '');
    setCurrentTenantId(data.codeTenant);

    const menusResponse = await refetchMenus();
    const updatedMenuList = menusResponse.data || [];

    const stylesResponse = await refetchStyles();
    const updatedStyle = stylesResponse.data || [];

    await refetchRoles();
    localStorage.setItem('user', JSON.stringify(rolesList) || '');

    if (updatedMenuList.length > 0) {
      localStorage.setItem('vite_tenant_id', data.codeTenant);
      if (updatedStyle) {
        localStorage.setItem(
          'tower_styles',
          JSON.stringify({
            id: updatedStyle.id,
            main_logo_url: updatedStyle.main_logo_url === '' ? null : updatedStyle.main_logo_url,
            white_logo_url: updatedStyle.white_logo_url === '' ? null : updatedStyle.white_logo_url,
            primary_color: isValidHexaColor(updatedStyle.primary_color) ? updatedStyle.primary_color : null,
            secondary_color: isValidHexaColor(updatedStyle.secondary_color) ? updatedStyle.secondary_color : null,
          }),
        );
        setClientStyle(
          JSON.stringify({
            id: updatedStyle.id,
            main_logo_url: updatedStyle.main_logo_url === '' ? null : updatedStyle.main_logo_url,
            white_logo_url: updatedStyle.white_logo_url === '' ? null : updatedStyle.white_logo_url,
            primary_color: isValidHexaColor(updatedStyle.primary_color) ? updatedStyle.primary_color : null,
            secondary_color: isValidHexaColor(updatedStyle.secondary_color) ? updatedStyle.secondary_color : null,
          }),
        );
      } else {
        localStorage.removeItem('tower_styles');
      }
      setHasNewConnection(true);
      setUserStorageData(response);
      localStorage.setItem('menus', JSON.stringify(updatedMenuList));
      setMenus(updatedMenuList as MenuItem[]);
      await handleLanguageOnLogin(response);
      setLoginStatus('authenticated');
      setNoUserAllowedErrorMessage(null);
      prevUrl && setPrevUrl(prevUrl);
    } else {
      setNoUserAllowedErrorMessage({ code: 'no_user_allowed_error_message' });
    }
  };

  useEffect(() => {
    const hasChildren = (towerInstance: any) => towerInstance?.has_children;
    const isValid = () => {
      if (!values?.firstTowerInstance) {
        return false;
      }
      if (hasChildren(values?.firstTowerInstance) && !values?.secondTowerInstance) {
        return false;
      }
      if (hasChildren(values?.secondTowerInstance) && !values?.thirdTowerInstance) {
        return false;
      }
      return true;
    };
    setIsButtonDisabled(!isValid());
  }, [values, setIsButtonDisabled]);

  return (
    <Dialog open={openModal} maxWidth="sm" fullWidth>
      <Box>
        <Card>
          <CardHeader
            title={t('swap_building_title')}
            titleTypographyProps={{ variant: 'h5' }}
            sx={{ borderBottom: `1px solid #ebe9f1`, width: '100%' }}
          />
          <CardContent sx={{ paddingBottom: '0px' }}>
            <BuildingSelector
              t={t}
              values={values}
              setValues={setValues}
              setSecondGroupLogin={setSecondTowersGroup}
              setThirdGroupLogin={setThirdTowersGroup}
            />
          </CardContent>
          <CardActions>
            <Grid container direction="row" justifyContent="flex-end" gap={1}>
              <LoadingButton
                variant="contained"
                size="small"
                type="submit"
                sx={{ width: { xs: '100%', sm: 'auto' } }}
                loading={isButtonLoading}
                disabled={isButtonDisabled}
                onClick={() => onSubmit(values)}
              >
                {t('select_tower')}
              </LoadingButton>
            </Grid>
          </CardActions>
        </Card>
        <Grid container item xs={12} justifyContent="flex-end" />
      </Box>
    </Dialog>
  );
};

const propTypes = {};

interface ExtraProps {
  openModal: boolean;
  response: any;
  prevUrl: string | undefined | null;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
SelectTowerModal.propTypes = propTypes;

export default SelectTowerModal;
