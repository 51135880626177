import { Box, FormHelperText, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { FormikErrors, FormikValues } from 'formik';
import { FormEvent } from 'react';
import CustomInput from '@/src/components/forms/CustomInput';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';

const CarSettingsModal = (props: Props) => {
  const {
    t,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    errorMessage,
    isSubmitLoading,
  } = props;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
          <Grid container spacing={2} pt={1} direction={{ xs: 'column', sm: 'row' }}>
            <Grid item md={12} sm={12} xs={12}>
              <CustomInput
                field="parking_space_id"
                errors={errors}
                label={t('car_concierge_form_parking_space_id_field_label', { ns: 'carSettings' })}
                placeholder={t('car_concierge_form_parking_space_id_field_placeholder', { ns: 'carSettings' })}
                setFieldValue={setFieldValue}
                values={values}
                autoFocus
                maxLength={4}
              />
            </Grid>
            {errorMessage && (
              <Grid item xs={12} sm={8} md={6}>
                {errorMessage.code === 'ParkingSpaceIdNotFound' ? (
                  <FormHelperText error>
                    {t(`${errorMessage.code}`, { ns: 'errors' })}
                  </FormHelperText>
                ) : (
                  <ErrorHelper error={errorMessage} />
                )}
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <LoadingButton
              loading={isSubmitLoading}
              variant="contained"
              size="small"
              type="submit"
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            >
              {t('form_button_submit_text_car_submit', { ns: 'carSettings' })}
            </LoadingButton>
          </Grid>
    </Box>
  );
};

const propTypes = {
  t: PropTypes.func.isRequired,
};

interface ExtraProps {
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  errors: FormikErrors<FormikValues>;
  errorMessage: { code: string; message: string; property_name: string } | null;
  isSubmitLoading: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
CarSettingsModal.prototypes = propTypes;

export default CarSettingsModal;
