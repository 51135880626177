import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import { TFunction } from 'i18next';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { CarRequestType } from '../types';

export const CarSettingsPage = (props: Props) => {
  const { columns, actionColumns, t } = props;

  return (
    <CustomDataTable
      title={t('elevator_page_title', { ns: 'carSettings' })}
      columns={columns}
      resource="vehicle_elevators"
      pathApi="iot/v1/vehicle-elevators"
      listName="vehicle_elevators"
      defaultSortFieldId="id"
      defaultSortAsc={false}
      canSearch
      canExport
      customActionColumns={actionColumns}
    />
  );
};

const propTypes = {};

interface ExtraProps {
  columns: CustomDataTableColumnsProps<CarRequestType>[];
  actionColumns: ActionColumn[];
  // headerActions: ActionHeader[];
  t: TFunction;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
CarSettingsPage.propTypes = propTypes;
