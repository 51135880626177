import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useFormik } from 'formik';
import { tRequiredFieldError } from '@/src/utilities/helpers/appConstants';
import DocumentsForm from './DocumentsForm';
import { createDocument, getDocuments, getDocumentsType } from '@/src/api/endpoints/documents';
import { DocumentBodyRequest } from '../types';
import { getProperties } from '@/src/api/endpoints/residents';
import { PropertyType } from '../../household/vehicles/types';
import { DataTableFormModal } from '@/src/components/custom-data-table/types/DataTableFormModal';

export const DocumentsFormContainer = ({ close, setSnackBarMessage }: DataTableFormModal) => {
  const { t } = useTranslation();
  const { mutate } = useMutation(createDocument);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const getDocumentsData = async () => {
    const response = await getDocuments();
    return response.data;
  };

  const { refetch } = useQuery(['documents'], getDocumentsData);

  const getDocumentsTypeData = async () => {
    const response = await getDocumentsType();
    return response.data;
  };

  const {
    data: DocumentTypeList,
    isLoading: isLoadingType,
    refetch: refetchDocumentsType,
  } = useQuery(['documents_type'], getDocumentsTypeData);

  const { data: response, isLoading } = useQuery(
    ['getProperties'],
    () => getProperties({}),
    
  );
  const propertiesList: Array<PropertyType> | undefined | null = response?.data.properties;
  const properties = propertiesList?.map((prop) => prop.code);

  const getInitialValues = () => ({
    document_category_id: '',
    description: '',
    document: '',
    residency_ids: [],
  });

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        document_category_id: Yup.object()
          .shape({
            id: Yup.number(),
            name: Yup.string(),
          })
          .required(tRequiredFieldError),
        description: Yup.string().required(tRequiredFieldError),
        document: Yup.string().required(tRequiredFieldError),
        residency_ids: Yup.array(),
      }),
    );

  const onSubmit = (data: any) => {
    const body: DocumentBodyRequest = {
      document_category_id: data?.document_category_id?.id,
      description: data?.description,
      file_url: data?.document,
      ResidencyIds: data?.residency_ids?.map((x: any) => x),
    };

    mutate(body, {
      onSuccess: async () => {
        refetch();
        refetchDocumentsType();
        setSnackBarMessage(t('documents_successful_document_creation_snackbar_text', { ns: 'documents' }));
        // eslint-disable-next-line no-restricted-globals
        close();
      },
      onError: async () => {
        setErrorMessage(t('documents_failed_document_creation_snackbar_text', { ns: 'documents' }));
      },
    });
  };

  const { handleSubmit, values, setFieldValue, errors, setFieldError } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  const childProps = {
    t,
    handleSubmit,
    values,
    setFieldValue,
    setFieldError,
    errors,
    errorMessage,
    setSnackBarMessage,
    DocumentTypeList,
    isLoadingType,
    properties,
  };

  return <DocumentsForm {...childProps} />;
};

export default DocumentsFormContainer;
