import { axiosAuthenticationInstance } from '../instances/authInstance';
import {
    AssignVehicleRequest,
} from '../types/types';

// CREATE
// EDIT
export const AssignVehicle = (req: AssignVehicleRequest) =>
  axiosAuthenticationInstance.put(`/iot/v1/vehicle-elevators/${req?.vehicle_id}/${req?.residency_id}`, req?.body);
// GET
export const getVehicleElevatorRequestStates = () => axiosAuthenticationInstance.get(`iot/v1/vehicle-elevators/requests/states`);